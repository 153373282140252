import { ONBOARDING_FEATURES } from '@dtx-company/true-common/src/constants/featureFlags'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { getAskOnboardingQuestions } from '@dtx-company/app/components/OnboardingQuestions/utils/onboardingQuestionsStore'
import { isValidDynamicRoute } from '@dtx-company/true-common/src/utils/urls/isValidDynamicRoute'
import { reverseEnumLookup } from '@dtx-company/true-common/src/utils/types/reverseEnumLookup'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useCallback } from 'react'
import { useDefaultPathname } from '../useDefaultPathname'
import { useFlowFeature } from '../useFlowFeature/useFlowFeature'
import { useFlowRouter } from '../../hooks/useFlowRouter'
import qs from 'query-string'

const getValidRedirectDestination = (pathname: string, fallback = Routes.CODES): string => {
  const isValidRoute = Boolean(reverseEnumLookup(Routes, pathname)) || isValidDynamicRoute(pathname)
  return isValidRoute ? pathname : fallback
}

export const useHandleFlowcodeUserRedirect = (): (() => Promise<void>) => {
  const router = useFlowRouter()
  const [onboardingQuestions] = useFlowFeature(ONBOARDING_FEATURES, { autoUpdate: true })
  const redirectTo = router.getString('redirectTo')
  const decodedRedirectTo = redirectTo ? decodeURIComponent(redirectTo) : ''
  const { url: redirectToUrl, query } = qs.parseUrl(decodedRedirectTo)
  const billingPlanType = useBillingPlanType()
  const isEnterprise = billingPlanType === PlanType.ENTERPRISE
  const defaultPathname = useDefaultPathname()

  const handleFlowcodeUserRedirect = useCallback(async (): Promise<void> => {
    const params = router.query
    const scanToOwnParams = { batchId: params.batchId }
    const redirectTo = params.redirectTo?.toString()
    const askOnboardingQuestions =
      !!getAskOnboardingQuestions() && !isEnterprise && onboardingQuestions
    fireAnalyticsEvent('useHandleFlowcodeUserRedirect_onboarding_questions', {
      onboardingQuestionsFeatureFlag: onboardingQuestions,
      defaultPathname,
      homepageFeatureFlag: undefined,
      getAskOnboardingQuestions: getAskOnboardingQuestions(),
      isEnterprise: isEnterprise
    })
    if (params.batchId && !redirectTo) {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_params_batchId_no_redirectTo')
      router.push({
        pathname: Routes.STO_GENERIC,
        query: scanToOwnParams
      })
    } else if (decodedRedirectTo) {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_decodedRedirectTo')
      const nextPath = getValidRedirectDestination(redirectToUrl)
      router.push({
        pathname: askOnboardingQuestions ? Routes.ONBOARDING_QUESTIONS : nextPath,
        query: askOnboardingQuestions
          ? qs.stringify({ ...query, redirectNext: nextPath })
          : qs.stringify(query)
      })
    } else {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_defaultPathname', {
        defaultPathname,
        homepageFeatureFlag: undefined
      })
      const nextPath = defaultPathname
      router.push(
        askOnboardingQuestions
          ? {
              pathname: Routes.ONBOARDING_QUESTIONS,
              query: { redirectNext: nextPath }
            }
          : nextPath
      )
    }
  }, [
    router,
    decodedRedirectTo,
    isEnterprise,
    onboardingQuestions,
    defaultPathname,
    redirectToUrl,
    query
  ])

  return handleFlowcodeUserRedirect
}
