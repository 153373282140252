import { DEFAULT_PAGE_ID } from '@dtx-company/true-common/src/constants/page'
import { FC, useEffect, useRef, useState } from 'react'
import { GET_PAGE_PERMISSION } from '@dtx-company/flow-codegen/src/page/queries'
import { PagePermissionQuery } from '@dtx-company/flow-codegen/src/page/generated.types'
import { SlotType } from '../../containers/settings/settings.types'
import { checkGeoEnabled } from '@app/common/src/hooks/useDisplayGeoInterstitial'
import { fetchInterstitialData } from './utils'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useFlowpageAnalyticsCollector } from '../../hooks/useFlowpageAnalyticsCollector'
import { useIsEULoaded } from '@dtx-company/inter-app/src/redux/selectors/currentSessionSelectors'
import { usePageDisplayContext } from '../../context/page-display'

export interface GeolocationData {
  latitude?: number
  longitude?: number
  accuracyMeters?: number
  isInterstitial?: boolean
}

export const PageTracker: FC<{ headers?: Record<string, string | null | undefined> }> = ({
  headers
}) => {
  const [hasTracked, setHasTracked] = useState(false)
  const { page, setIsOwnPage, setGeoData, setPixelPassthrough } = usePageDisplayContext()
  const { trackPageView } = useFlowpageAnalyticsCollector({ isPreview: false, isEditMode: false })
  const isEULoaded = useIsEULoaded()
  const { isAuthenticated, isAuthChecked, token } = useAuthState()
  const geoEnabled = checkGeoEnabled(page)

  //access ref to call page view with correct cookie/EU State data
  const geoPageViewRef = useRef({ trackPageView, setGeoData })
  useEffect(() => {
    geoPageViewRef.current = { trackPageView, setGeoData }
  }, [trackPageView, setGeoData])

  useEffect(() => {
    const pageId = page?.id
    if (!hasTracked && isEULoaded && isAuthChecked && pageId && pageId !== DEFAULT_PAGE_ID) {
      const geoEnabledAction = async (): Promise<void | Promise<void>> => {
        const eventId = await trackPageView({ useHeap: true, headers, shouldCollectEventId: true })
        if (geoEnabled) {
          fetchInterstitialData(geoPageViewRef, headers, eventId)
        }
      }
      if (isAuthenticated) {
        pageGqlFetcher<PagePermissionQuery>(
          GET_PAGE_PERMISSION,
          {
            pageId
          },
          token
        ).then(({ checkPageOwnership }) => {
          const tempIsOwnPage = Boolean(checkPageOwnership)
          if (!tempIsOwnPage) {
            geoEnabledAction()
          }
          setIsOwnPage(tempIsOwnPage)
        })
      } else {
        geoEnabledAction()
      }
      setHasTracked(true)
    }
  }, [
    isEULoaded,
    setIsOwnPage,
    isAuthChecked,
    isAuthenticated,
    page,
    trackPageView,
    hasTracked,
    geoEnabled,
    headers,
    token
  ])

  useEffect(() => {
    const isEnabled = page?.slots?.find(
      slot => slot?.slotMeta?.type === SlotType.SETTINGS_FLOWCODE_PIXEL
    )?.data?.isEnabled
    if (isEnabled) {
      setPixelPassthrough(true)
    }
  }, [page, setPixelPassthrough])
  return null
}
