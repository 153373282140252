import {
  DESKTOP_FOOTER_BREAKPOINT,
  DESKTOP_FOOTER_HEIGHT,
  TABLET_FOOTER_BREAKPOINT
} from '../styles'
import { media, mediaMaxWidth } from '@app/common/src/theme'
import { theme } from '@dtx-company/shared-components/src'
import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  ${mediaMaxWidth.custom(DESKTOP_FOOTER_BREAKPOINT - 1)} {
    display: none;
  }
  background-color: ${theme.colors.primary.black};
  display: grid;
  box-sizing: content-box;
  line-height: 1.15;
  padding: 52px;
  grid-area: footer;
  height: ${DESKTOP_FOOTER_HEIGHT}px;
  grid-template-areas:
    'logo links'
    'trademark trademark';
  grid-template-columns: 260px minmax(0, 1fr);
  grid-template-rows: 90% 10%;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
`

export const MobileFooterWrapper = styled.footer`
  ${media.custom(DESKTOP_FOOTER_BREAKPOINT)} {
    display: none;
  }
  grid-area: footer;
  align-self: end;
  z-index: 1000;
  background-color: ${theme.colors.primary.black};
  display: flex;
  flex-direction: column;
  padding: ${({ padding }: { padding?: string }) => padding || '35px'};
  ${media.custom(TABLET_FOOTER_BREAKPOINT)} {
    padding: ${({ padding }: { padding?: string }) => padding || '35px'};
  }
  ${media.custom(DESKTOP_FOOTER_BREAKPOINT)} {
    padding: ${({ padding }: { padding?: string }) => padding || '0 96px 0 50px'};
    height: 100px;
  }
`

export const FooterLinks = styled.ul<{ textAlign?: string; margin?: number | string }>`
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  columns: 2;
  margin: ${({ margin }) => margin || '0'};
  ${media.custom(TABLET_FOOTER_BREAKPOINT)} {
    columns: 3;
  }
  ${media.custom(DESKTOP_FOOTER_BREAKPOINT)} {
    columns: 1;
    li:not(:last-child) {
      padding-bottom: 4px;
    }
  }
`

export const FooterAnchor = styled.a`
  :link,
  :active,
  :hover,
  :visited {
    text-decoration: none;
  }
  margin: 2px;
  ${media.custom(DESKTOP_FOOTER_BREAKPOINT)} {
    margin: 0 5px;
  }
  cursor: pointer;
`

export const FooterIcons = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  max-width: 280px;
`

export const FooterIcon = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }
`
